import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { DarkTheme } from './Themes';
import { Develope } from './AllSvgs';

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte';

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 2rem 1rem;
`;

const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  height: auto;
  z-index: 3;
  line-height: 1.5;
  cursor: pointer;
  font-family: 'Ubuntu Mono', monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);

  ${Main}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.8em + 0.5vw);
  padding: 0.5rem 0;

  ${Main}:hover & {
    color: ${(props) => props.theme.body};
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  ul,
  p {
    margin-left: 1.5rem;
    line-height: 1.6;
  }
`;

const MySkillsPage = () => {
    return (
        <ThemeProvider theme={DarkTheme}>
            <Box>
                <LogoComponent theme="dark" />
                <SocialIcons theme="dark" />
                <PowerButton/>
                <ParticleComponent theme="dark" />

                <Main>
                    <Title>
                        <Develope width={40} height={40} /> Full-Stack Developer
                    </Title>
                    <Description>
                        I am passionate about building scalable and efficient web applications. My expertise lies in creating full-stack solutions that deliver great user experiences while maintaining robust backend systems.
                    </Description>
                    <Description>
                        <strong>Skills</strong>
                        <p>
                            MERN Stack, Next.js, Spring, Spring Boot, Tailwind CSS, Web Sockets, JWT Authentication, React, Redux, JavaScript (ES6+), HTML5, CSS3, Node.js, Express.js, MongoDB, RESTful APIs, Firebase, Java, DBMS.
                        </p>
                    </Description>
                    <Description>
                        <strong>Tools</strong>
                        <p>VS Code, GitHub, Postman, Figma, CodePen, Docker, Netlify, Heroku.</p>
                    </Description>
                    <Description>
                        <strong>Key Projects</strong>
                        <ul>
                            <li>Real-Time Chat Application using React and Socket.io.</li>
                            <li>Amazon UI Clone built with React.</li>
                            <li>Real-Time Weather Forecast Application in React.</li>
                            <li>Employee Test Portal with Dynamic Question Flow using Next.js.</li>
                            <li>Full Online Test Application with Adaptive Difficulty.</li>
                        </ul>
                    </Description>
                </Main>

                <BigTitle text="SKILLS" top="80%" right="30%" />
            </Box>
        </ThemeProvider>
    );
};

export default MySkillsPage;
