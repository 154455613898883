// work data

export const Work = [
    {
        id:1,
        name:"Dynamic Employee Test Portal",
        description:"It is build on top of the NextJs, Focused on best user experiance & security.",
        tags:["NextJs","JavaScript","TailWind Css","MongoDB"],
        
        demo:"https://testemp-4us1.vercel.app/",
        github:"https://github.com/HARSHMISHRA0408/"
    },
    {
        id:2,
        name:"SprinBoot + ReactJS Employee Management System",
        description:"A Job listing website build in ReactJS. With searching and filtering functionalities.",
        tags:["React","Java","SpringBoot"],
        
        demo:"https://github.com/HARSHMISHRA0408/",
        github:"https://github.com/HARSHMISHRA0408/"
        
    },
    {
        id:3,
        name:"Real Time Chat Application",
        description:"A clone of Dev.to build using ReactJS. With realtime fetching of articles from dev.to using API.",
        tags:["react","api"],
        
        demo:"https://real-time-chat-room-lovat.vercel.app/#",
        github:"https://github.com/HARSHMISHRA0408/real-time-chat-room"
    },
    {
        id:4,
        name:"Wheather Forecast Application",
        description:"A cool looking sidebar menu build using ReactJS and styledComponents.It has really smooth animations.",
        tags:["react","styledComponents","react-router"],
        
        demo:"https://github.com/HARSHMISHRA0408/",
        github:"https://github.com/HARSHMISHRA0408/wheather-data-using-fatch-api"
       
    },{
        id:5,
        name:"Amazon UI Clone",
        description:"A todo app build using Redux-toolkit and framer-motion for smooth animations.",
        tags:["React","TailWind Css"],
        
        demo:"https://amazon-ui-clone-lilac.vercel.app/",
        github:"https://github.com/HARSHMISHRA0408/amazon-ui-clone"
        
    },
    {
        id:5,
        name:"Other or Minor Projects",
        description:"A todo app || Task Manager || Quiz Application || .",
        tags:["react","redux","NextJs","TailWind Css"],
        
        demo:"https://amazon-ui-clone-lilac.vercel.app/",
        github:"https://github.com/HARSHMISHRA0408/amazon-ui-clone"
        
    },

    
  
] 