import React from 'react';
import styled, { keyframes, ThemeProvider } from 'styled-components';
import { DarkTheme } from './Themes';
import astronaut from '../assets/Images/spaceman.png'

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte';

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const float = keyframes`
  0% { transform: translateY(-10px); }
  50% { transform: translateY(15px) translateX(15px); }
  100% { transform: translateY(-10px); }
`;

const Spaceman = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  width: 20vw;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
  }
`;

const TimelineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 70%;
  position: relative;
`;

const TimelineContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 2px solid ${(props) => props.theme.text};
  padding: 2rem;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
`;

const TimelineItem = styled.div`
  margin: 1.5rem 0;
  padding-left: 1rem;
  position: relative;
  &:before {
    content: '\u2022';
    color: ${(props) => props.theme.text};
    font-size: 1.5rem;
    position: absolute;
    left: -1rem;
    top: 0;
  }
`;

const Title = styled.h3`
  font-size: calc(1rem + 0.5vw);
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: calc(0.8rem + 0.2vw);
  color: ${(props) => props.theme.text};
  line-height: 1.5;
`;

const AboutPage = () => {

    const experienceTimeline = [
        {
            year: 'Work Experiance ',
            title: '💼🎯',
            description: '',
        },
        {
            year: 'Oct 2024 – Working',
            title: 'Joined Viyal Technology, Chennai',
            description: 'Developed a software solution for employee testing, streamlining the onboarding process and simplifying assessments for companies..',
        },
        {
            year: 'July 2024 – Sept 2024',
            title: 'Joined Rablo.in',
            description: 'Focused on scalable and user-friendly front-end development.',
        },
        {
            year: 'Nov 2022 – Feb 2023',
            title: 'Joined Intolo India Private Limited',
            description: 'Gained experiance on working with team & how to work on real time projects.',
        },
    ];

    const educationTimeline = [
        {
            year: 'Education',
            title: '📚📖',
            description: '',
        },
        {
            year: '2023-2025 ',
            title: 'Pursuing MCA at VIT Chennai',
            description: 'Master of Computer Application (GPA: 8.42 / 10.00).',
        },
        {
            year: '2020-2023',
            title: 'Nachiketa College Of Computer Science, Jabalpur',
            description: 'Bachelor of Computer Application (Percentage: 69.45).',
        },
        {
            year: '2019-2020',
            title: '12th, Grace Mission Hr. Sec School, Sleemanabad',
            description: 'Physics Chemistry Math (74%).',
        },
        {
            year: '2017-2018',
            title: '10th, Grace Mission Hr. Sec School, Sleemanabad',
            description: 'MP State Board (87%).',
        },

    ];



    return (
        <ThemeProvider theme={DarkTheme}>
            <Box>
                <LogoComponent theme="dark" />
                <SocialIcons theme="dark" />
                <PowerButton />
                <ParticleComponent theme="dark" />
                <Spaceman>
                    <img src={astronaut} alt="spaceman" />
                </Spaceman>


                <TimelineWrapper>


                    <TimelineContainer>
                        {/* <BigTitle text="Experience" top="0" left="55%" /> */}
                        {experienceTimeline.map((item, index) => (
                            <TimelineItem key={index}>
                                <Title>
                                    {item.year}: {item.title}
                                </Title>
                                <Description>{item.description}</Description>
                            </TimelineItem>
                        ))}
                    </TimelineContainer>

                    <TimelineContainer>
                        {/* <BigTitle text="Educationd" top="0" left="10%" /> */}
                        {educationTimeline.map((item, index) => (
                            <TimelineItem key={index}>
                                <Title>
                                    {item.year}: {item.title}
                                </Title>
                                <Description>{item.description}</Description>
                            </TimelineItem>
                        ))}
                    </TimelineContainer>
                </TimelineWrapper>

                <BigTitle text="ABOUT" top="10%" left="5%" />
            </Box>
        </ThemeProvider>
    );
};

export default AboutPage;
