import React from 'react';
import styled, { keyframes, ThemeProvider } from 'styled-components';
import { DarkTheme } from './Themes';
import astronaut from '../assets/Images/spaceman.png';
import sql from '../assets/Images/sqlcertificate.png';
import python from '../assets/Images/python.png';
import intolo from '../assets/Images/intolo.png';
import pw from '../assets/Images/pw.png';

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte';

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const float = keyframes`
  0% { transform: translateY(-10px); }
  50% { transform: translateY(15px) translateX(15px); }
  100% { transform: translateY(-10px); }
`;

const Spaceman = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  width: 20vw;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
  }
`;

const TimelineContainer = styled.div`
  position: absolute;
  left: 10%;
  top: 15%;
  width: 80%;
  height: 70%; /* Make sure this is enough to show all items and enable scroll */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  /* Align items from the top */
  align-items: flex-start;
  padding: 2rem;
  border-left: 2px solid ${(props) => props.theme.text};
  overflow-y: scroll;  /* Enable scrolling */
  
  /* Hide the scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;
`;


const TimelineItem = styled.div`
  margin: 1.5rem 0;
  padding-left: 1rem;
  position: relative;
  &:before {
    content: '\u2022';
    color: ${(props) => props.theme.text};
    font-size: 1.5rem;
    position: absolute;
    left: -1rem;
    top: 0;
  }
`;

const Title = styled.h3`
  font-size: calc(1rem + 0.5vw);
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: calc(0.8rem + 0.2vw);
  color: ${(props) => props.theme.text};
  line-height: 1.5;
`;

const CertificateImage = styled.img`
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const Certificate = () => {
  const timelineData = [
    {
      year: '2025',
      title: 'Data Science & Machine Learning Certification by PW Skills',
      description: 'Ongoing.',
      image: pw, // Add path to certificate image
    },
    {
      year: '2024',
      title: 'SQL Certification by SkillUp',
      description: 'Completed.',
      image: sql,
    },
    {
      year: '2023',
      title: 'Intolo India Certification',
      description: 'Internships Completion Certificate.',
      image: intolo,
    },
    {
      year: '2022',
      title: 'Python Certification by Great Learning',
      description: 'Completed.',
      image: python,
    },
  ];

  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />
        <ParticleComponent theme="dark" />

        <Spaceman>
          <img className="pic" src={astronaut} alt="spaceman" />
        </Spaceman>

        <TimelineContainer>
          {timelineData.map((item, index) => (
            <TimelineItem key={index}>
              <Title>{item.year}: {item.title}</Title>
              <Description>{item.description}</Description>
              {item.image && (
                <CertificateImage src={item.image} alt="Certificate" />
              )}
            </TimelineItem>
          ))}
        </TimelineContainer>

        <BigTitle text="ABOUT" top="10%" left="5%" />
      </Box>
    </ThemeProvider>
  );
};

export default Certificate;
