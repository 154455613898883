// blog data

export const Blogs = [
    {
        id:1,
        name:"Visit Personal Blog Website",
        tags:["react","gsap","styled-components"],
        date:"13 May, 2021",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://careersparxprep.online/"
    },
    {
        id:2,
        name:"How to become full stack developer",
        tags:["react","styled-components"],
        date:"5 July, 2021",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://careersparxprep.online/become-best-java-web-developer/"
    },
    {
        id:3,
        name:"Web Development Vs Web Development",
        tags:["react","react-lottie","styled-components"],
        date:"6 July, 2021",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://careersparxprep.online/web-development-vs-software-development/"
    },
   
] 